/* Login.js */

.register-margin {
  margin-top: 100px;
}
.register-margin h1 {
  margin-bottom: 0;
}
.forgotpassword-margin {
  margin-top: 200px;
}
#registration-form {
  margin-top: 50px;
}
.register-logo {
  width: 150px;
  height: 40px;
  position: absolute;
  right: 20px;
  top: 20px; 
}
.register-ad-bg-image {
  height: 100vh;
  width: 100%;
}
.register-ad-text {
  position: absolute;
  top: 0;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center; 
}
.register-ad-text h3 {
  color: #fff;
}
.register-ad-text p {
  text-align: center;
  color: #fff;
  margin-left: 20px;
  margin-right: 20px;
}
.register-ad-image { 
position: absolute;
height: 250px;
margin-top: 150px; 
}
.register-footer {
position: absolute;
bottom: 10px;
right: 10px;
color: #fff;
}


/* Logout */

.logout-icon {
  margin-bottom: 15px;
}


.sign-in-signup-btn {
  margin-bottom: 50px;
}
.sign-in-email,
.sign-in-password,
.sign-in-button {
   width: 300px;
}
.sign-in-forgotpassword {
  text-align: right;
  margin-right: 15px;
  width: 300px;
}
.sign-in-subdomain-info 
.anticon-arrow-left  {
  background: #e5e8ec;
  padding: 4px 10px;
  border-radius: 50px;
}
.sign-in-subdomain-name {
  color: rgb(80, 79, 79);  
}