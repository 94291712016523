.navbar-collapse {
    justify-content: center;
}

.bordered-div {
    border: 1px solid black;
    padding: 10px;
}

.chart-pair {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.chart-pair>div {
    width: 50%;
    margin: 10px;
}
.container {
    display: flex;
    justify-content: left;
}

.box {
    border: 1px solid black;
    background-color: rgb(208, 205, 208);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px;
    height: 150px;
    width: 200px;
}

.valuep {
    font-size: medium;
}

.chart-card {
    border-radius: 10px;
    border: 2px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    padding: 10px;
}