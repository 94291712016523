.overall-cont {
  margin: 50px;
}
.detail-section {
  padding: 10px;
}
.detail-section {
  padding: 10px;
}
.user-cont {
  /* background-color: rgba(255, 228, 196, 0.219); */
  border: 0;
  border-radius: 6px;
}
.address-cont {
  background-color: rgba(255, 228, 196, 0.219);
  border: 0;
  border-radius: 6px;
}
.user-data {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 20px;
}

.edit-button {
  margin: 20px 30px 10px;
}

/* edit page */
.Edit-cont {
  width: 85%;
  margin: 20px auto 100px;
  border: 2px solid #f2f2f2;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.task-cont {
  margin: 20px 0;
  background-color: #dddddd5d;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

h5 {
  margin-bottom: 0px;
  cursor: pointer;
}

.status,
.due_date,
.priority {
  font-size: 14px;
  font-weight: 400;
  padding-right: 20px;
  color: #8e8f93;
  padding-top: 4px;
}

.circle {
  width: 12px;
  height: 12px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #8e8f93;
  margin-right: 10px;
  cursor: pointer;
}

.circle:hover {
  background-color: #32955e;
  border: 2px solid #32955e;
}

.planned {
  margin: 20px auto;
  background-color: #8e8f93;
  border-radius: 24px;
  color: #fff;
  text-align: center;
  width: 150px;
  padding: 10px 20px;
  cursor: pointer;
}
