.toolbar-pagination {
    margin-right: 5px;
    background-color: #fff;
    border-radius: 5px;
}

.toolbar-pagination .anticon-left,
.toolbar-pagination .anticon-right {
    background-color: #e5ebf7;
    color: #2a5fa3;
    padding: 7px 4px;
    cursor: pointer;
}

.toolbar-pagination .anticon-left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.toolbar-pagination .anticon-right {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.toolbar-pagination .toolbar-pagination-numbers {
    color: #2a5fa3;
    padding-left: 5px;
    padding-right: 5px;
}