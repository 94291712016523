@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.add-form {
  height: 80vh;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  overflow: scroll;
}
.ant-collapse {    
  border: none;
}
.add-form .ant-collapse-header {
  border: 1px solid #edf0f5;
  border-bottom: none;
  background-color: #fff;
  font-weight: 500;
}
.add-form .ant-collapse-header .anticon {
  padding-top: 5px !important;
}
.add-form .ant-collapse-content-box {
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);  
}
.add-form .ant-form-item-label label {
  font-weight: 400; 
  color: #4d6483;   
}
.add-form .ant-form-item {
  margin-bottom: 10px;
}
.add-form .ant-input {  
  border-radius: 3px;
  border: 1px solid #e0dede;
}
.add-form .ant-picker {
  border-radius: 3px !important;
  border: 1px solid #e0dede !important;       
}
.add-form .ant-select-selector {  
  border-radius: 3px !important;
  border: 1px solid #e0dede !important;       
}
.add-form .ant-input-affix-wrapper {
  border-radius: 3px !important;
  border: 1px solid #e0dede !important;
}
.add-form .ant-input-group-addon {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;  
}
.add-form .ant-select .ant-select-arrow .anticon {
  position: absolute;
  top: -14px;
  right: 3px;  
  padding-top: 13px !important;
  padding-bottom: 13px !important;  
}
.add-form-description .ant-form-item-label label {
  position: absolute;
  left: 100px;
}
.add-form-description .ant-form-item-control-input {
  margin-left: -200px;
}

.list-table {
  margin-left: 10px;
  margin-right: 10px; 
  padding-right: 0 !important;    
}
.list-table-scroller {
  height: 80vh;
  overflow: scroll;
}
.list-table .ant-tabs{  
  margin-top: -30px;
}
.list-table .ant-tabs-nav-wrap {
  height: 30px;
}
.list-table .ant-tabs-tab {
  background-color: #2a5fa3 !important;
  color: #fff;
  border: none !important;
}
.list-table .ant-tabs-tab:hover {
  color: #fff;
}
.list-table .ant-tabs-tab-active {
  background-color: #e6ecf8 !important;
  border: none;  
}
.list-table .ant-tabs-nav::before {
  display: none;
}
.ant-list {      
  border-radius: 5px;    
}
.list-table .ant-list-item {
  background: #fff;  
  border-radius: 5px;
  border: 1px solid #e2dfdf !important; 
  padding-top: 5px;     
  padding-bottom: 5px;  
}
.list-table .ant-list-item:not(:nth-child(1)) {  
  margin-top: 10px;
}
.leadslist-listitem-title {
  font-weight: 400;
  color: #214069;  
  margin-bottom: 0;
  text-transform: capitalize;
}
.leadslist-listitem-info {
  margin-bottom: 0;
}
.leadslist-listitem-info-source {
  color: #214069;
  font-weight: 400;
}
.leadslist-listitem-info-status {
  color: #214069;
  font-weight: 400;
}
.leadslist-listitem-createdby {  
  margin-bottom: 0px;  
}
.leadslist-listitem-createdby span {
  margin-left: 5px;
}
.leadslist-listitem-createdby span:nth-child(1) {
  margin-top: -15px;
}
.leadslist-listitem-info-two {  
  float: right;
  margin-right: 10px;
}
.leadslist-listitem-delete {
  position: absolute;
  right: 15px;
  top: -20px;
  margin-bottom: 0;
  cursor: pointer;  
}
.leadlist-tags-wrapper {
  margin-left: 20px;
}
.leadlist-tag {
  text-transform: capitalize;
}
