.main-toolbar {
    background: #214069;
    height: 5.2vh;  
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .main-toolbar-functions-wrapper {
    position: absolute;
    right: 10px;  
    color: #fff;
    display: flex;
    align-items: center;  
    z-index: 1;
  }
  .main-toolbar-functions-wrapper .anticon-filter {
    font-size: 18px;
    padding: 5px;
    color: #e5ebf7;
    background-color: #395c86;
    border-radius: 5px;
    margin-right: 5px;
    height: 4vh;
    cursor: pointer;
  }
  .main-toolbar-functions-wrapper .ant-dropdown-trigger {
    padding-left: 5px;
    padding-right: 5px;  
    height: 28px;
    border-radius: 5px;
    height: 4vh;
    display: flex;
    align-items: center;
  }
  .main-toolbar-functions-wrapper .ant-dropdown-trigger .anticon-down {
    margin-right: -14px;
    margin-top: -1px;
    background: #edf0f5;
    padding: 8px 5px 8px 5px;
    color: #2a5fa3;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .main-toolbar-functions-wrapper .ant-dropdown-trigger span:nth-child(1) {
    font-size: 12px;
  }
  .main-toolbar-breadcrumb {
    margin-left: 10px;
    font-size: 13px;
  }
  .main-toolbar-breadcrumb .ant-breadcrumb-link a,
  .main-toolbar-breadcrumb .ant-breadcrumb-separator {  
    color: #fff;  
  }
  .main-toolbar-breadcrumb li:last-child span a {
    color: #fff;
    font-weight: 500;
  }