.filter-toolbar {
  background-color: #214069;
  min-height: 5.2vh;
  display: 'flex'; 
  align-items: center;
  justify-content: center;
}

.filter-toolbar label{
  color: #FFFFFF
}

.filter-toolbar .a{
  color: #FFFFFF
}

.filter-toolbar .ant-form-item {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
}


.filter-toolbar .ant-col {
  margin-bottom: 5px;
}