.overall-Cont {
  width: 70%;
  margin: 50px auto;
  padding: 10px 0;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}

.edit-button {
  margin: auto 10px;
  float: right;
}
.detail-section {
  padding: 10px;
}
.user-cont {
  background-color: rgba(255, 228, 196, 0.219);
  border: 0;
  border-radius: 6px;
}

.assign-cont {
  background-color: rgba(196, 245, 255, 0.265);
  border: 0;
  border-radius: 6px;
}
.user-data-title {
  padding: 20px 10px 0;
  margin-bottom: 2px;
  font-size: 13px;
  text-transform: uppercase;
}
.user-data {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  padding: 10px 10px 20px;
}

/* .ant-form-item {
  width: 49% !important;
  margin-bottom: 0px !important;
}  */

/* .ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 3px !important;
} */
.other-data {
  padding: 20px;
}

.save-cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.main-box {
  margin-bottom: 30px;
}
