.overall-cont {
  margin: 50px;
}
.task-sec {
  margin-left: 40px;
}
.task-field .ant-input {
  line-height: 2;
  border-radius: 5px;
  margin-bottom: 20px;
}
.task-icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.global-class-name {
  border: 1px solid gray;
  padding: 5px;
  width: 40px;
  height: 30px;
  cursor: pointer;
}
.calender {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}
.time-date,
.add-details,
.free-busy,
.description,
.link-details {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}
.detail-sec {
  margin-left: 20px;
}
.span-text {
  color: #317ae2;
  cursor: pointer;
}
.span-text:hover {
  text-decoration: underline;
}
.ql-editor,
.ql-toolbar.ql-snow {
  background-color: #fffcdc;
}
.ant-space {
  display: flex !important;
  justify-content: space-between !important;
}
.react-time-picker__button:enabled {
  display: none;
}
.react-datepicker-wrapper input {
  width: 100px;
  margin-left: 10px;
  border: 1px solid gray;
  border-radius: 2px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100px !important;
}
.time1 .react-time-picker__wrapper {
  margin-left: 20px;
}
.time2 .react-time-picker__wrapper {
  margin-left: 15px;
}
.react-time-picker__wrapper {
  border-radius: 2px;
  border: thin solid gray;
}
.main-desc .ql-editor{
  height: 100px!important;
}
.comment-desc .ql-editor{
  height: 65px!important;
}
.main-desc .ql-editor{
  background-color: #fffcdc;
}
.main-desc .ql-toolbar.ql-snow{
  background-color: #fffcdc;
}
.comment-desc .ql-editor{
  background-color: transparent;
}
.description{
  background-color: #fffcdc;
  border-radius: 8px;
  padding:20px 10px ;
  margin: 15px 50px;
}
.footer{
  display: flex;
  justify-content: end;
  margin: 0 50px 30px;
}
.cancel-btn{
  border: 0;
  background: #dddddd;
  border-radius: 4px;
  padding: 5px 10px;
  margin-right: 10px;
  /* color: #fff; */
}
.save-btn{
  border: 0;
  background: #3e79f7;
  border-radius: 4px;
  padding: 5px 15px;
  color: #fff;
}
.topic{
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  padding: 8px 5px;
  background-color: #A9A9A9;
  color: #fff;
  width: 100px;
  margin: 50px auto 20px;

  border-radius: 20px;
}

.comment{
  font-size: 14px;
  color: gray;
  font-weight: 500;
  margin: 10px 50px 10px 75px;
  cursor: pointer;
}
