/* 
  Global  
*/
.spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
}
.ant-upload-span {
  margin-top: -85px;  
}
.ant-upload-select, .ant-upload {
  width: 100px !important;
}
.btn {
  border-radius: 5px;
  border: none;
  height: 4vh;
  padding: 6px;
  display: flex;
  align-items: center;  
  margin-right: 5px;
}

.btn .anticon {  
  height: 2vh;
  border: none !important;   
  border-radius: 20px;  
}
.fw-3 {
  font-weight: 300;
}
.fw-4 {
  font-weight: 400;
}
.fw-5 {
  font-weight: 600;
}
.fw-6 {
  font-weight: 600;
}
.bg-dodgerblue {
  background-color: #2689eb;  
}
.bg-white {
  background-color: #fff;
}
.bg-darkblue {  
  background-color: #2a5fa3;
}
.fs-12 {
  font-size: 12;
}
.text-white {
  color: #fff;
}
.text-darkblue {  
  color: #2a5fa3;
}
.text-dodgerblue {
  color: #2689eb;  
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.required {
  border-left: 2px solid red !important;
  border-radius: 3px;
}
.ant-form-item-required::before {
  display: none !important;
}

/*  
  Page: LeadsList.js  
*/

.scrumboard h1 {
  text-align: center;  
}

.leads-toolbar-list-todo { 
  margin-right: 5px;     
}
.leads-toolbar-list-todo .anticon-appstore,
.leads-toolbar-list-todo .anticon-unordered-list {
  font-size: 18px;
  padding: 5px;  
  height: 4vh;
  cursor: pointer;
}
.leads-toolbar-list-todo .anticon-appstore {  
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;  
}
.leads-toolbar-list-todo .anticon-unordered-list {  
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.icon-select {
  background-color: #e5ebf7;
  color: #395c86;
}
.icon-unselect {
  color: #e5ebf7;
  background-color: #395c86;
}
.leadslist-listitem-title {
  font-weight: 400;
  color: #214069;  
  margin-bottom: 0;
  text-transform: capitalize;
}
.leadslist-listitem-info {
  margin-bottom: 0;
}
.leadslist-listitem-info-source {
  color: #214069;
  font-weight: 400;
}
.leadslist-listitem-info-status {
  color: #214069;
  font-weight: 400;
}
.leadslist-listitem-createdby {  
  margin-bottom: 0px;  
}
.leadslist-listitem-createdby span {
  margin-left: 5px;
}
.leadslist-listitem-createdby span:nth-child(1) {
  margin-top: -15px;
}
.leadslist-listitem-info-two {  
  float: right;
  margin-right: 10px;
}
.leadslist-listitem-delete {
  position: absolute;
  right: 15px;
  top: -20px;
  margin-bottom: 0;
  cursor: pointer;  
}
.leadlist-tags-wrapper {
  margin-left: 20px;
}
.leadlist-tag {
  text-transform: capitalize;
}


/*  
  Page: Add Lead.js  
*/
.contact-plus-outlined .ant-input-group-addon {
  cursor: pointer;
}
.ant-dropdown-bg .anticon {
  background: #f7f7f8;  
  margin-right: -13px;
  padding-left: 12px;
  padding-right: 13px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;  
}


.mg{
  margin-bottom: 20px;
}

/* Contact Drawer  */


